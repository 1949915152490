
import React, { useRef } from "react";

function LoadingComp({ isFullPage }) {

    const ref = useRef(null);
    React.useEffect(() => {
        import("@lottiefiles/lottie-player");
    });

    return (
        <div className={isFullPage == true ? 'flex flex-col h-[80vh]' : 'flex flex-col justify-center items-center'}>
            <div className="mt-10  h-28">
                <lottie-player src="/animate/loading.json" background="transparent" speed="1" loop autoplay></lottie-player>
            </div>        </div>
    )

}

export default LoadingComp






