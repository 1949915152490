
import Router, { useRouter } from "next/router";
import { SessionProvider, useSession } from "next-auth/react"
import { NextIntlProvider } from 'next-intl';
import '../styles/globals.css'
import Head from 'next/head'
import LoadingComp from "../components/loading_comp";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { useEffect } from "react";


function getDirection(locale) {
  if (locale == "ar") {
    return "rtl";
  }
  return "ltr";
}


const MyApp = ({ Component, pageProps: { session, ...pageProps }, }) => {

//////////////Adding a service worker into your Next.js application///////////////////
  // useEffect(() => {
  //   if("serviceWorker" in navigator) {
  //     window.addEventListener("load", function () {
  //      navigator.serviceWorker.register("/sw.js").then(
  //         function (registration) {
  //           console.log("Service Worker registration successful with scope: ", registration.scope);
  //         },
  //         function (err) {
  //           console.log("Service Worker registration failed: ", err);
  //         }
  //       );
  //     });
  //   }
  // }, [])


  const { locale } = useRouter();
  return (
    <>
      <Head>
        <title>طلعه - tal3a</title>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <div dir={getDirection(locale)} >
        <NextIntlProvider messages={pageProps.messages} onError={() => null}>
          <SessionProvider session={session}>
            <GoogleAnalytics trackPageViews />
            {Component.auth ? (
              <Auth>
                <Component {...pageProps} />
              </Auth>
            ) : (
              <Component {...pageProps} />
            )}
          </SessionProvider>
        </NextIntlProvider>
      </div>
    </>

  );
}

export default MyApp

function Auth({ children }) {
  const { status } = useSession({ required: true })
  const session = useSession()
  const router = useRouter()
  if (status === "loading") {
    return <LoadingComp />
  }
  if (status === "authenticated") {
    if (session.data.user.email === process.env.ADMIN_USER) {
      return children
    } else {
      router.push('/')
    }
  }
}